import React, { useState } from 'react'
import { Button,  Label, Input, Card } from 'reactstrap';
import "./login.scss"

import logoDark from "../../images/logo-dark.svg";
import { login } from '../../Api'
import { useNavigate } from "react-router-dom";


function Login() {

  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const [loginFailed,setLoginFailed] = useState(false)
  let navigate = useNavigate();



  const HandleLogin = async() => {

    if (username !== "" && password !== "")
    {
      const resp = await login(username, password);
      // console.log("Response",resp)

      if (resp.status === 200) {
        // console.log('login',resp);
        window.sessionStorage.setItem("token",resp.data.token);

        navigate('/csv')

      }else{
        // console.log("in else",resp)
        setLoginFailed(true)
      }


    }else{
      console.log("else");
      setLoginFailed(true)
    }
  }


  return (
    <div className="login">
      <Card className="m-auto">
      <div className='text-center mb-3'>
          <a href="/home" className="text-brand"><img src={logoDark} alt="Logo Dark" /></a>
        </div>
      <div className='mb-3 w-100'>
          <Label className='username small'> Username</Label>
        <Input type="text" value={username}  
        onChange={(event) => {
            setLoginFailed(false);
          setUsername(event.target.value);}}
          autoFocus
          ></Input>
      </div>
      
      <div className='mb-3 w-100'>
        <Label className='password small'>Password</Label>
        <Input type="password" 
        value={password}
        onKeyDown={(event) =>{
          if (event.key === 'Enter') {HandleLogin();}
        }}
        onChange={(event) => {
          setLoginFailed(false);
          setPassword(event.target.value);
        }}
        ></Input>
      </div>
      
        <Button color="success w-100" className='button mb-2' onClick={ HandleLogin} >Login</Button>
        {/* <span className='forgot mt-3'>Forgot Password</span> */}
        { loginFailed ?
          <p className='login-failed mt-1 mb-0 text-center'>Login failed !!</p>
        :""}
      </Card>

    </div>
  
  )
}

export default Login