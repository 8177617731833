import "./home.scss";
import {  Col, Card} from 'reactstrap';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
// import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import Topbar from "../../Components/Topbar";
import { useNavigate } from "react-router-dom";
// import {useSidebar} from '../../context/sidebarContext.jsx';

function Home() {
  let navigate = useNavigate();

  return (
    
    <div className="home">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100 px-3">
        
        <div className="content-box">
          <div className="content-box-inner p-md-4 p-3">
              <div className="my-3">
                <h6 className="text-center text-dark">Get Benefit Statements From</h6>
                <h2 className="text-center text-dark fw-600">Product Reviews</h2>
                <div className="row pb-3 justify-content-center mx-0">
                  <Col md="4" xs="12" className="px-xxl-5 px-lg-4 py-xxl-2 py-0 my-3">
                    <Card className="p-4 flex-column align-items-center position-relative h-100 rounded-4">
                    <div className="icon-bg p-3">
                      <ListAltTwoToneIcon sx={{fontSize: "2em"}}/>
                    </div>
                    <h4 className="mt-4 mb-3 fw-bold text-center"><a href="# " className="stretched-link" onClick={() => {navigate("/csv");}}>Upload CSV</a></h4>
                    <p className="text-center mb-0">Get the benefit statement of reviews you have in CSV file (exported from Helium10.)</p>
                    </Card>
                  </Col>
                  <Col md="4" xs="12" className="px-xxl-5 px-lg-4 py-xxl-2 py-0 my-3">
                    <Card className="p-4 flex-column align-items-center position-relative h-100 rounded-4">
                      <div className="icon-bg p-3">
                        <LocalOfferTwoToneIcon sx={{fontSize: "2em"}}/>
                      </div>
                    <h4 className="mt-4 mb-3 fw-bold text-center"><a href="# " className="stretched-link" onClick={() => {navigate("/scrapping");}}>Using ASIN</a></h4>
                    <p className="text-center mb-0">Get reviews of product from Amazon using the ASIN id of Amazon product</p>
                    </Card>
                  </Col>
                  <Col md="4" xs="12" className="px-xxl-5 px-lg-4 py-xxl-2 py-0 my-3">
                    <Card className="p-4 flex-column align-items-center position-relative h-100 rounded-4">
                      <div className="icon-bg p-3">
                        <ChatTwoToneIcon sx={{fontSize: "2em"}}/>
                      </div>
                    <h4 className="mt-4 mb-3 fw-bold text-center"><a href="# " className="stretched-link" onClick={() => {navigate("/reviews_input");}}>Paste Review  </a></h4>
                    <p className="text-center mb-0">Paste the reviews of the product. We will generate benefit statements from the review</p>
                    </Card>
                  </Col>
                </div>
              </div>
              <div className="my-3">
                <h6 className="text-center text-dark">Name the product and genrate</h6>
                <h2 className="text-center text-dark fw-600">Product Description</h2>
                <div className="row justify-content-center mx-0">
                  <Col md="4" xs="12" className="px-xxl-5 px-lg-4 py-xxl-2 py-0 mt-3">
                    <Card className="p-4 flex-column align-items-center position-relative h-100 rounded-4">
                      <div className="icon-bg p-3">
                        <ListAltTwoToneIcon sx={{fontSize: "2em"}}/>
                      </div>
                    <h4 className="mt-4 mb-3 fw-bold text-center"><a href="# " className="stretched-link" onClick={() => {navigate("/product_description");}}>Product Description</a></h4>
                    <p className="text-center mb-0">Get the benefit statement of reviews you have in CSV file (exported from Helium10.)</p>
                    </Card>
                  </Col>
                </div>
              </div>
              
              {/* <div className="card-container ">
                <div
                  className="card"
                  onClick={() => {
                    navigate("/csv");
                  }}
                >
                  Upload Csv
                </div>
                <div
                  className="card"
                  onClick={() => {
                    navigate("/scrapping");
                  }}
                >
                  Using ASIN
                </div>

                <div
                  className="card"
                  onClick={() => {
                    navigate("/reviews_input");
                  }}
                >
                  Paste reviews
                </div>
              </div> */}

            {/* <div className="my-3">
              <h6 className="text-center">Please click on below button to generate product description</h6>
              <div className="card-container ">
                <div
                  className="card"
                  onClick={() => {
                    navigate("/product_description");
                  }}
                >
                  Product Description
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
