import { useState } from "react";

function PdfInstructions() {

    const [isInstructionsVisible, setInstructionVisibility] = useState(false);

    const toggleInstructionsVisibility = () => {
        setInstructionVisibility(!isInstructionsVisible);
    };

    return (
        <div className="position-fixed end-0 instructions-block overflow-hidden">
            <div className={"d-flex position-relative instructions-block-inner " + (isInstructionsVisible ? "active" : "")}>
                <div className="align-self-center">
                    <button className={"btn fw-600 d-inline-block " + (isInstructionsVisible ? "btn-brand" : "btn-outline-brand")} style={{ transform: "rotate(90deg)", transformOrigin: "center" }} onClick={toggleInstructionsVisibility}>Instructions</button>
                </div>
                <div className="bg-white d-inline-block rounded-3 instruction-details ps-3">
                    <ol className="p-sm-4 p-3 mb-0 ">
                        <li>Upload pdf file (small file is recommended for demo).</li>
                        <li>Add your question in form input.</li>
                        <li>Hit "Ask AI" and wait for response.</li>
                        <li>You can ask another question for the same document</li>
                        <li>You can upload different file and ask the query again</li>
                        <li>The results may not be accurate all the time.</li>
                        <li>Follow up questions will not know about previous questions.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default PdfInstructions;