import React from 'react';

// The Context 
const SidebarContext = React.createContext();

// Template Provider
const SidebarProvider = ({children}) => {

    const [myValue, setMyValue] = React.useState(true);

    // Context values passed to consumer
    const value = {
        myValue,    // <------ Expose Value to Consumer
        setMyValue  // <------ Expose Setter to Consumer
    };

    return (
        <SidebarContext.Provider value={value}>
            {children}
        </SidebarContext.Provider>
    )
}

// Template Consumer
const SidebarConsumer = ({children}) => {
    return (
        <SidebarContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('SidebarConsumer must be used within SidebarProvider');
                }
                return children(context)
            }}
        </SidebarContext.Consumer>
    )
}

// useTemplate Hook
const useSidebar = () => {
    const context = React.useContext(SidebarContext);
    if(context === undefined)
        throw new Error('useSidebar must be used within SidebarProvider');
    return context;
}

export {
    SidebarProvider,
    SidebarConsumer,
    useSidebar
}