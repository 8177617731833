import { useState, useEffect } from "react";
import "./scrapping.scss";
import { Container, Row, Col, Toast, ToastBody, Tooltip} from 'reactstrap';
import { addAsin, getStatus} from "../../Api";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
// import StarIcon from '@mui/icons-material/Star';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Topbar from "../../Components/Topbar";
import LeftSidebar from "../../Components/LeftSidebar";
// import {useSidebar} from '../../context/sidebarContext.jsx';


function Scrapping() {
  const [asin, setAsin] = useState("");
  const [status, setStatus] = useState("");
  const [gotResponse, setGotResponse] = useState(false);
  const [gotStatements, setGotStatements] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [error, setError] = useState(false);
  const [reviewCount, setReviewCount] = useState([]);
  const totalreview= reviewCount[5] + reviewCount[4] + reviewCount[3] + reviewCount[2] + reviewCount[1];
  const avgreview= (reviewCount[5] + reviewCount[4] + reviewCount[3] + reviewCount[2] + reviewCount[1]) / (totalreview);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toastOpen,setToastOpen] = useState(false);
  // const {myValue, setMyValue} = useSidebar();


  const toggle = () => setTooltipOpen(!tooltipOpen);
  let navigate = useNavigate();

  useEffect(() => {
    // setMyValue(false);

    // console.log("token",window.sessionStorage.getItem("token"))
    if (window.sessionStorage.getItem("token") == null) {
      navigate("/");
    }

    getAsinStatus();
  });

  // TO close the toast after 2 seconds
  useEffect(()=> {
    if (toastOpen){
      const timer = setTimeout(() => {
        setToastOpen(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  },[toastOpen])

  const getAsinStatus = async () => {
    const token = window.sessionStorage.getItem("token", "");
    const resp = await getStatus(token);
    console.log("Response", resp);

    if (resp.status === 200) {
      console.log("login", resp);
      setAsin(resp.data.asin);
      setStatus(resp.data.status);
    } else {
      console.log("in else", resp);
      // setError(true);
    }
  };


  // const getAsinStatements = async () => {
  //   const token = window.sessionStorage.getItem("token", "");
  //   const resp = await getStatements(token);
  //   console.log("Response", resp);

  //   if (resp.status === 200) {
  //     console.log("login", resp);
  //     setGotStatements(true)
  //     setStatements(resp.data.statements)
  //     setReviewCount(resp.data.review_count)
  //   } else {
  //     console.log("in else", resp);
  //     // setError(true);
  //   }
  // };

  const [isActive, setActive] = useState(false);
    
  const InstructionShow = () => {
    // console.log('click');
    setActive(!isActive);
  };

  const HandleUpload = async () => {
    console.log("button");
    if (asin !== null && !loading) {
      setLoading(true);
      setError(false);
      setStatements([]);
      setReviewCount({});
      setGotResponse(false);
      setGotStatements(false);

      const token = window.sessionStorage.getItem("token", "");
      const resp = await addAsin(token, asin);
      console.log("Response", resp);

      if (resp.status === 200) {
        console.log("login", resp);
        setLoading(false);
        setGotResponse(true);
        getAsinStatus();
        setToastOpen(true)
      } else {
        console.log("in else", resp);
        setError(true);
      }

      setLoading(false);
    } else {
    }
  };

  return (
    
    <div className="scrapping">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100">
      <LeftSidebar pos="2"></LeftSidebar>
        <div className="content-box position-relative">
          <div className="content-box-inner">
          <div className="d-flex flex-column position-relative relative-section">
              <div className="bg-white header-section">
                <Container className="p-4">
                  <h6 className="fw-600">Get the review Analysis Using ASIN</h6>
                </Container>
              </div>
              <div className="py-3 response-section">
                <Container className="p-4">
                  <Row className="">
                    <div className="response-inner">
                      {asin !== "" ? (
                        <div className="col-md-6">
                        <div className="asin row border rounded mx-0">
                          <div className="col-6 text-center px-0 border-end">
                          <p className="mb-0 py-2 border-bottom"><span>Asin :</span> </p>
                          <p className="mb-0 py-2"> <strong className="py-1 d-inline-block">{asin}</strong></p>
                          </div>
                          <div className="col-6 text-center px-0">
                          <p className="mb-0 py-2 border-bottom"><span>Status :</span> </p>
                          <p className="mb-0 py-2">
                            <strong className={"fw-600 mb-0 small px-3 py-1 alert d-inline-block " + (status === 'success' ? "text-brand alert-success" : status === 'failed' ? "text-red alert-danger" : "text-info alert-info")}>{status} 
                              <a href=" #" id="TooltipExample"><ErrorOutlineIcon style={{size: 12, paddingLeft: 5}} className={(status === 'pending' || status === 'processing') ? "d-inline-block" : "d-none"}/></a> 
                            </strong>
                            <Tooltip isOpen={tooltipOpen} target="TooltipExample" toggle={toggle} placement="bottom" autohide="true"> Please hold on it will take some time</Tooltip>
                          </p>
                          </div>
                          </div>
                        {/* <div className="asin">

                          <p className="mb-1"><span>Asin :</span> <strong>{asin}</strong></p>
                          <p className="mb-1"><span>Status :</span> <strong className={status === 'success' ? "text-brand fw-500" : "text-red fw-500" }>{status}</strong></p>
                          {status === "success" ? 
                          <button className="button" onClick={getAsinStatements}>Get statements</button>
                          :"" }
                          {status === "pending" || status === "processing" ? 
                          <p className="asin-text alert alert-warning p-2 my-2 small"><AccessTimeIcon  className="me-2"/>Please come back after sometime.</p>
                          :"" }
                        </div> */}
                        </div>
                      ) : ("")}
                      {gotStatements ? (
                      <div className="">
                        <div className="benefit-data pt-2 pb-4">
                          <h4 className="fw-600">Benefit Statements</h4>
                            <ul className="my-3 ps-2 mb-0">
                            {statements.map((item, index) => (
                              <li className="my-1" key={{ index }}>{item}</li>
                            ))}
                            </ul>
                        </div>
                        <div className="review-data pt-2 pb-4 row">
                          <h4 className="fw-600">Reviews</h4>
                          <Col lg="6">
                          <Row className="align-items-center">
                            <Col md="3" sm="12" className="text-md-left text-center">
                              <h2 className="h1">{avgreview.toFixed(1)}</h2>
                              <p className="small">{totalreview} reviews</p>
                            </Col>
                            <Col className="">
                              <div className="d-flex align-items-center justify-content-between my-1">
                                <div className="progress w-85">
                                  <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["5"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["5"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p className="ms-3 mb-0 text-end">{reviewCount["5"]}</p>
                              </div>
                              <div className="d-flex align-items-center justify-content-between my-1">
                                <div className="progress w-85">
                                  <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["4"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["4"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p className="ms-3 mb-0 text-end">{reviewCount["4"]}</p>
                              </div>
                              <div className="d-flex align-items-center justify-content-between my-1">
                                <div className="progress w-85">
                                  <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["3"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["3"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p className="ms-3 mb-0 text-end">{reviewCount["3"]}</p>
                              </div>
                              <div className="d-flex align-items-center justify-content-between my-1">
                                <div className="progress w-85">
                                  <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["2"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["2"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p className="ms-3 mb-0 text-end">{reviewCount["2"]}</p>
                              </div>
                              <div className="d-flex align-items-center justify-content-between my-1">
                                <div className="progress w-85">
                                  <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["1"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["1"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p className="ms-3 mb-0 text-end">{reviewCount["1"]}</p>
                              </div>
                            </Col>
                          </Row>
                          </Col>
                        </div>
                      </div>
                      ) : ("")}
                    </div>
                  </Row>
                </Container>
                </div>
                {loading ? (
                  <div className="loading bg-transparent h-100" style={{minHeight: "100%"}}>
                    <div id="loading-spinner">
                    </div>
                  </div>
                ) : ("")}
                <div className="position-fixed end-0 instructions-block overflow-hidden">
                <div className={"d-flex position-relative instructions-block-inner " + (isActive? "active" : "")}>
                    <div className="align-self-center">
                        <button className={"btn fw-600 d-inline-block "  + (isActive? "btn-brand" : "btn-outline-brand")} style={{ transform: "rotate(90deg)", transformOrigin: "center center"}} onClick={InstructionShow}>Instructions</button>
                    {/* <button className=" bottom-0 btn btn-info flex-shrink-1 d-inline-block" onClick={InstructionShow}>Instructions</button> */}
                    </div>
                    <div className="bg-white d-inline-block rounded-3 instruction-details ps-3">
                      <ol className="p-sm-4 p-3 mb-0 ">
                        <li>Paste the ASIN id of the Amazon product in the input</li>
                        <li>The reviews will be fetched from "Amazon.com"</li>
                        <li>First all the reviews of the product will be fetched.</li>
                        <li>Then we will process all the reviews.</li>
                        <li>Do not try to submit another ASIN while one is being processed.</li>
                      </ol>
                    </div>
                </div>
              </div>
              <div className="mt-auto py-2 upload-section position-sticky border-top bottom-0 w-100 bg-brand-light">
                <Container className="p-md-4 p-3">
                  <div className="mt-auto p-1 mb-2 upload-inner mx-auto">
                      <p className="error mb-2 text-danger small">{error ? "Some error occured, please try again !" : ""}</p>
                      {status === "" || status === "success" || status === "failed" ? 
                      <div className="d-flex align-items-center mx-auto">
                      <input className="asin-label form-control me-md-4 me-3" autoFocus type="text" onChange={(e) => { setAsin(e.target.value); }} placeholder="Asin number here"></input>
                      <button className="btn btn-brand text-nowrap" onClick={HandleUpload} >Start Analysis</button>
                      </div>
                      :
                      <div class="d-flex align-items-center mx-auto">
                        <input className="form-control text-danger opacity-75 small bg-gray me-md-4 me-3" readOnly value="Last ASIN is still processing"></input>
                        <button className="btn btn-brand pe-none disabled text-nowrap">Start Analysis</button>
                      </div>}
                      {/* <p className="box-p-tag text-">You cannot add another ASIN untill the last one is processed</p>}   */}
                  </div>
                </Container>
              </div>
          </div>
            {/* <Col lg="6" className="p-md-3 p-2">
              <Card className="p-md-4 p-3 upload-card rounded-4 h-100">
                <div className="p-1">
                  <div className="upload">
                    <h4 className="text-start fw-600">Put the ASIN number below to start Analysis.</h4> */}
                    {/* <div className="file-input my-3">
                      <p className="error">
                        {error ? "Some error occured, please try again !" : ""}
                      </p>
                      {status === "" || status === "success" || status === "failed" ? 
                      <div className="d-flex align-items-center">
                      <input className="asin-label form-control me-md-4 me-3" autoFocus type="text" onChange={(e) => { setAsin(e.target.value); }} placeholder="Asin number here"></input>
                      <button className="btn btn-brand w-25" onClick={HandleUpload} >Submit</button>
                      </div>
                      :
                      <div class="d-flex align-items-center">
                        <input className="form-control text-danger opacity-75 small bg-gray me-md-4 me-3" readOnly value="Last ASIN is still processing"></input>
                        <button className="btn btn-brand pe-none disabled w-25">Submit</button>
                      </div>}
                    </div> */}
                    
                    {/* {asin !== "" ? (
                      <>
                      <div className="asin row border rounded mt-4 mx-0">
                        <div className="col-6 text-center px-0 border-end">
                        <p className="mb-0 py-2 border-bottom"><span>Asin :</span> </p>
                        <p className="mb-0 py-2"> <strong className="py-1 d-inline-block">{asin}</strong></p>
                        </div>
                        <div className="col-6 text-center px-0">
                        <p className="mb-0 py-2 border-bottom"><span>Status :</span> </p>
                        <p className="mb-0 py-2">
                          <strong className={"fw-600 mb-0 small px-3 py-1 alert d-inline-block " + (status === 'success' ? "text-brand alert-success" : status === 'failed' ? "text-red alert-danger" : "text-info alert-info")}>{status} 
                            <a id="TooltipExample"><ErrorOutlineIcon style={{size: 12, paddingLeft: 5}} className={(status === 'pending' || status === 'processing') ? "d-inline-block" : "d-none"}/></a> 
                          </strong>
                          <Tooltip isOpen={tooltipOpen} target="TooltipExample" toggle={toggle} placement="bottom" autohide="true"> Please hold on it will take some time</Tooltip>
                        </p>
                        </div>
                        </div> */}
                      {/* <div className="asin">

                        <p className="mb-1"><span>Asin :</span> <strong>{asin}</strong></p>
                        <p className="mb-1"><span>Status :</span> <strong className={status === 'success' ? "text-brand fw-500" : "text-red fw-500" }>{status}</strong></p>
                        {status === "success" ? 
                        <button className="button" onClick={getAsinStatements}>Get statements</button>
                        :"" }
                        {status === "pending" || status === "processing" ? 
                        <p className="asin-text alert alert-warning p-2 my-2 small"><AccessTimeIcon  className="me-2"/>Please come back after sometime.</p>
                        :"" }
                      </div> */}
                      {/* </>
                    ) : ("")} */}
                  {/* </div>
                  <hr className="my-5" />
                  <div className="mt-3">
                      <h4 className="fw-500">Instructions</h4>
                      <ol className="ps-4">
                        <li className="my-1">Paste the ASIN id of the Amazon product in the input</li>
                        <li className="my-1">The reviews will be fetched from "Amazon.com"</li>
                        <li className="my-1">First all the reviews of the product will be fetched.</li>
                        <li className="my-1">Then we will process all the reviews.</li>
                        <li className="my-1">The results may not accurate all the time.</li>
                        <li className="my-1">Do not try to input another product while one is being processed.</li>
                      </ol>
                  </div>
                </div>
              </Card>
            </Col> */}
            {/* <Col lg="6" className="p-md-3 p-2">
              <Card className="response-card rounded-4 p-md-4 p-3 h-100">
                {loading ? (
                  <div className="loading bg-transparent my-5 py-5">
                    <div id="loading-spinner">
                    </div>
                  </div>
                ) : ("")}
                
                {gotStatements ? (
                  <div className="p-1">
                    <div className="benefit-data">
                      <h4 className="fw-600">Benefit Statements</h4>
                        <ul className="my-3 ps-2 mb-0">
                        {statements.map((item, index) => (
                          <li className="my-1" key={{ index }}>{item}</li>
                        ))}
                      </ul>
                  </div>
                  <hr className="my-4" />
                  <div className="review-data">
                    <h4 className="fw-600">Reviews</h4>
                    
                    <div className="row align-items-center">
                      <Col md="3" sm="12" className="text-md-left text-center">
                        <h2 className="h1">4.5</h2>
                        <p className="small">{totalreview} reviews</p>
                      </Col>
                      <Col className="">
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <div className="progress w-85">
                            <div className="progress-bar bg-brand" role="progressbar" aria-label="Basic example" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p className="ms-3 mb-0 text-end">{reviewCount[5]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <div className="progress w-85">
                            <div className="progress-bar bg-brand" role="progressbar" aria-label="Basic example" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p className="ms-3 mb-0 text-end">{reviewCount[4]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <div className="progress w-85">
                            <div className="progress-bar bg-brand" role="progressbar" aria-label="Basic example" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p className="ms-3 mb-0 text-end">{reviewCount[3]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <div className="progress w-85">
                            <div className="progress-bar bg-brand" role="progressbar" aria-label="Basic example" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p className="ms-3 mb-0 text-end">{reviewCount[2]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <div className="progress w-85">
                            <div className="progress-bar bg-brand" role="progressbar" aria-label="Basic example" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p className="ms-3 mb-0 text-end">{reviewCount[1]}</p>
                        </div>
                      </Col>
                    </div>
                    
                  </div>
                </div>
                ) : ("")}
              </Card>
            </Col> */}
          </div>
          {gotResponse ? (
                      // <div className="response text-success my-2"> Asin added Successfully </div>
                      <Toast isOpen={toastOpen}  className="p-2">
                            <ToastBody className="position-relative px-3 mx-1"><TaskAltIcon className="me-3 text-brand" />Asin added Successfully</ToastBody>
                        </Toast>
                        // <Toast isOpen={toastOpen} >
                        //     <ToastHeader fade="true" icon="success" aria-live="assertive" aria-atomic="true">
                        //       Success
                        //     </ToastHeader>
                        //     <ToastBody >Asin added Successfully</ToastBody>
                        // </Toast>
                    ) : ("")}
        </div>
      </div>
      {/* <div>
        <Button
          color="primary"
          onClick={function noRefCheck(){}}
        >
          Click Me
        </Button>
        <br />
        <br />
        <Toast className="p-3 bg-secondary my-2">
                <ToastHeader>
                    Sample Toast Header
                </ToastHeader>
                <ToastBody>
                    Sample Toast Body Text
                </ToastBody>
            </Toast>
        
      </div> */}
      {/* <div className="instructions">
        <p className="heading">Instructions</p>
        <p>1. Paste the ASIN id of the Amazon product in the input</p>
        <p>2. The reviews will be fetched from "Amazon.com"</p>
        <p>3. First all the reviews of the product will be fetched.</p>
        <p>4. Then we will process all the reviews.</p>
        <p>5. The results may not accurate all the time.</p>
        <p>6. Do not try to input another asin while one is being processed.</p>
      </div> */}
      {/* <div className="middle">
        <h3>Put the ASIN number below to start Analysis.</h3>
        <div className="upload">
          <div className="file-input">
            <p className="error">
              {error ? "Some error occured, please try again !" : ""}
            </p>
            
            {status === "" || status === "success" || status === "failed" ? 
            <>
            <input
            className="asin-label"
            // autoFocus
            type="text"
            onChange={(e) => {
              setAsin(e.target.value);
            }}
            placeholder="Asin number here"
            ></input>
            <button onClick={HandleUpload} >Submit</button>
            </>
            :<p className="box-p-tag">Last ASIN is still processing</p>}  
          </div>
        </div>

        {gotResponse ? (
          <div className="response"> Asin added Successfully </div>
        ) : (
          ""
        )}

        {asin !== "" ? (
          <div className="asin">
            <p> <b>Asin :</b> <span>{asin}</span></p>
            <p><b>Status : </b><span>{status}</span></p>
            {status === "success" ? 
            <button className="button" onClick={getAsinStatements}>Get statements</button>
            :"" }
            {status === "pending" || status === "processing" ? 
            <p className="asin-text">Please come back after sometime.</p>
            :"" }
          </div>
        ) : (
          ""
        )}
        {gotStatements ? (
          <div className="review-count">
            <h4>
              Total reviews :{" "}
              {parseInt(reviewCount[1]) +
                parseInt(reviewCount[2]) +
                  parseInt(reviewCount[3]) +
                    parseInt(reviewCount[4]) +
                      parseInt(reviewCount[0])}
            </h4>
            <p className="star5">
              5 <StarIcon className="star" /> : {reviewCount[4]}
            </p>
            <p className="star4">
              4 <StarIcon className="star" /> : {reviewCount[3]}
            </p>
            <p className="star3">
              3 <StarIcon className="star" /> : {reviewCount[2]}
            </p>
            <p className="star2">
              2 <StarIcon className="star" /> : {reviewCount[1]}
            </p>
            <p className="star1">
              1 <StarIcon className="star" /> : {reviewCount[0]}
            </p>
          </div>
        ) : (
          ""
        )}
      </div> */}

      {/* <div className="benefit">
        <p>Benefit Statements</p>

        {gotStatements ? (
          <ul>
            {statements.map((item, index) => (
              <li key={{ index }}>{item}</li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
}

export default Scrapping;
