import { useState, useEffect } from "react";
import "./reviewsinput.scss";
import {  Container, Row } from 'reactstrap';
import { review_analysis } from "../../Api";
import { useNavigate } from "react-router-dom";
import Topbar from "../../Components/Topbar";
import LeftSidebar from "../../Components/LeftSidebar";
// import {useSidebar} from '../../context/sidebarContext.jsx';


function ReviewsInput() {
  const [reviews, setReviews] = useState("");
  const [statements,setStatements] = useState([]);
  const [gotResponse, setGotResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  let navigate = useNavigate();
  // const {myValue, setMyValue} = useSidebar();


  useEffect(() => {
    // setMyValue(false);

    // console.log("token",window.sessionStorage.getItem("token"))
    if (window.sessionStorage.getItem("token") == null) {
      navigate("/");
    }
  });


  const getReviewsInput
 = async () => {
    setLoading(true)
    setGotResponse(false)
    setError(false)
    const token = window.sessionStorage.getItem("token", "");
    const resp = await review_analysis(token,reviews);
    console.log("Response", resp);

    if (resp.status === 200) {
      console.log("login", resp);
      setGotResponse(true)
      setStatements(resp.data.statements);
      
    } else {
      console.log("in else", resp);
      setError(true);
    }

    setLoading(false)

  };
  const [isActive, setActive] = useState(false);
    
      const InstructionShow = () => {
        // console.log('click');
        setActive(!isActive);
      };

 
  return (
    <div className="reviews d-block">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100">
      <LeftSidebar pos="3"></LeftSidebar>
        <div className="content-box">
          <div className="content-box-inner">
            <div className="d-flex flex-column position-relative relative-section">
              <div className="bg-white header-section">
                <Container className="p-4">
                  <h6 className="fw-600">Exctract Benefit statements from the review</h6>
                </Container>
              </div>
              <div className="py-3 response-section">
                <Container className=" p-4">
                  <Row className="">
                  <div className="response-inner ">
                  
                  {gotResponse ? (
                  <div className="p-1">
                      <h4 className="mb-0 fw-600">Benefit Statements</h4>
                      <ul className="my-3 ps-2 mb-0">
                      {statements.map((item, index) => (
                          <li className="my-1" key={{ index }}>{item}</li>
                      ))}
                      </ul>
                  </div>
                ) : ("")}
                  </div>
                  </Row>
                </Container>
              </div>
              {loading ? (
                  <div className="loading bg-transparent h-100" style={{minHeight: "100%"}}>
                    <div id="loading-spinner">
                    </div>
                  </div>
                  ) : ("")}
              <div className="position-fixed end-0 instructions-block overflow-hidden">
                <div className={"d-flex position-relative instructions-block-inner " + (isActive? "active" : "")}>
                    <div className="align-self-center">
                        <button className={"btn fw-600 d-inline-block "  + (isActive? "btn-brand" : "btn-outline-brand")} style={{ transform: "rotate(90deg)", transformOrigin: "center center"}} onClick={InstructionShow}>Instructions</button>
                    {/* <button className=" bottom-0 btn btn-info flex-shrink-1 d-inline-block" onClick={InstructionShow}>Instructions</button> */}
                    </div>
                    <div className="bg-white d-inline-block rounded-3 instruction-details ps-3">
                      <ol className="p-sm-4 p-3 mb-0 ">
                        <li>Paste the reviews of the product.</li>
                        <li>We will generate benefit statements from these reviews.</li>
                        <li>The results may not be accurate all the time.</li>
                        <li>The results may not be accurate all the time.</li>
                        <li>Do not try to submit another review while one is being processed.</li>
                      </ol>
                    </div>
                </div>
              </div>
              <div className="mt-auto py-2 upload-section position-sticky border-top bottom-0 w-100 bg-brand-light">
                <Container className="p-md-4 p-3">
                    <div className="d-flex flex-column mt-auto mx-auto p-1 mb-2 upload-inner">
                        <p className="error mb-2 text-danger small">{error ? "Some error occured, please try again !" : ""}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <textarea className="review-textarea form-control" rows="1" style={{maxHeight : "300px",  overflowY: "auto", minHeight: "24px"}} autoFocus type="text" onChange={(e) => {setReviews(e.target.value);}} placeholder="Paste reviews here"></textarea>
                          <button className="btn btn-brand ms-3 text-nowrap" onClick={getReviewsInput}>Start Analysis</button>
                        </div>
                    </div>
                </Container>
              </div>
            </div>
              {/* <Col lg="6" className="p-md-3 p-2">
                <Card className="p-md-4 p-3 upload-card rounded-4 h-100">
                  <div className="p-1">
                    <div className="w-100 ">
                      <h4 className="text-center fw-600">Paste the reviews of product below.</h4>
                      <p className="error mb-0">{error ? "Some error occured, please try again !" : ""}</p>
                      <textarea className="review-textarea form-control my-4" rows="6" autoFocus type="text" onChange={(e) => {setReviews(e.target.value);}} placeholder="Paste reviews here"></textarea>
                      <div className="text-center">
                        <button className="btn btn-brand mb-3 mx-auto" onClick={getReviewsInput}>Start Analysis</button>
                      </div>
                    </div>
                    <hr className="my-5" />
                    <div className="mt-3">
                      <h4 className="fw-500">Instructions</h4>
                      <ul className="ps-4">
                        <li>Paste the reviews of the product.</li>
                        <li>We will generate benefit statements from these reviews.</li>
                        <li>The results may not be accurate all the time.</li>
                        <li>Do not try to input another product while one is being processed.</li>
                      </ul>
                    </div>
                  </div>
                </Card>
              </Col> */}
              {/* <Col lg="6" className="p-md-3 p-2">
                <Card className="benefit-card rounded-4 p-md-4 p-3 h-100">
                {loading ? (
                  <div className="loading bg-transparent my-5 py-5">
                    <div id="loading-spinner">
                    </div>
                  </div>
                ) : ("")}
                {gotResponse ? (
                  <div className="p-1">
                      <h4 className="mb-0 fw-600">Benefit Statements</h4>
                      <ul className="my-3 ps-2 mb-0">
                      {statements.map((item, index) => (
                          <li className="my-1" key={{ index }}>{item}</li>
                      ))}
                      </ul>
                  </div>
                ) : ("")}
                </Card>
              </Col> */}
            {/* {loading ? (
              <div className="loading bg-transparent my-5 py-5">
                <p>Processing</p>
                <div id="loading-spinner">
                </div>
                <p>It may take several minutes, Please wait.</p>
              </div>
            ) : ("")} */}
            {/* <div className="row my-4">
              <Col sm="12">
              {gotResponse ? (
                <Card className="benefit-card rounded-4 p-4">
                  <div className="row p-2">
                    <Col md="12">
                      <h4 className="mb-0">Benefit Statements</h4>
                      <ul className="mt-2 ps-2 mb-0">
                      {statements.map((item, index) => (
                          <li key={{ index }}>{item}</li>
                      ))}
                      </ul>
                    </Col>
                  </div>
                </Card>
                ) : ("")}
              </Col>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="instructions">
        <p className="heading">Instructions</p>
        <p>1. Paste the reviews of the product.</p>
        <p>2. We will generate benefit statements from these reviews.</p>
        <p>3. The results may not be accurate all the time.</p>
        <p>4. Do not try to input another product while one is being processed.</p>
      </div> */}
      {/* <div className="middle">
        <h3>Paste the reviews of product below.</h3>
        <div className="reviews">
          <div className="file-input">
            <p className="error">
              {error ? "Some error occured, please try again !" : ""}
            </p>
            
            <textarea
            className="reviews-label"
            autoFocus
            type="text"
            onChange={(e) => {
              setReviews(e.target.value);
            }}
            placeholder="paste reviews here"
            ></textarea>
            <button onClick={getReviewsInput
          } >Submit</button>
          </div>
        </div>

        {loading ? (
        <div className="progress">
          <p>Processing</p>
          <div id="loading-spinner">
          </div>
        </div>
      ) : (
        ""
        )}

      </div> */}

      {/* <div className="benefit">
          <p>Benefit Statements</p>

      {gotResponse ? (
          <ul>
            {statements.map((item, index) => (
              <li key={{ index }}>{item}</li>
            ))}
          </ul>
      ) : (
        ""
      )}
        </div> */}
    </div>
  );
}

export default ReviewsInput;
