import "./topbar.scss";
import MenuIcon from '@mui/icons-material/Menu';
// import LocalActivityIcon from '@mui/icons-material/LocalActivity';
// import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody} from 'reactstrap';
import logoLight from "../images/logo-light.svg";
import { useState } from "react";
import { Logout } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
import {useSidebar} from '../context/sidebarContext.jsx';

export default function Topbar() {

  const [openMenu,setOpenMenu] = useState(false)
  // let navigate = useNavigate();
  const {myValue, setMyValue} = useSidebar();

  const iconClicked = () =>{

    console.log("clicked");
    setOpenMenu(!openMenu);
    setMyValue(!myValue)

  }


  // const logout = () => {
  //   window.sessionStorage.setItem("token",null);
  //   // navigate('/')
  // }


  return <div className="topbar px-3">
        <div className="left my-1">
          <a href="/home">
            <img src={logoLight} alt="" />
          </a>
        </div>
        <div className="right px-1 ">
          < MenuIcon onClick={iconClicked} className="icon" />
          {openMenu ?           
          <div className="logout d-md-block d-none">
            <p className="mb-0 p-1"><a href="/" className="text-dark d-flex"><Logout className="icon text-red me-2"/> <span className="" >Logout</span></a></p>
            {/* <p>Settings</p> */}
          </div>
          : " " }
        </div>
        {/* <div className="right d-md-none d-block">
        < MenuIcon onClick={iconClicked} className="icon"/>
        {openMenu ?         
        <div className="w-100 h-100 position-fixed top-0 start-0" style={{backgroundColor: "rgba(60,60,60,0.5)"}}>  
          <div className="logout position-fixed h-100 top-0 end-0 rounded-0 w-75">
            <p className="mb-0 p-1"><a href="/" className="text-dark d-flex"><Logout className="icon text-red me-2"/> <span className="" >Logout</span></a></p>
          </div>
        </div>
          : " " }
        </div> */}
    </div>;
}
