import axios from "axios";
import { BASE_URL } from "./Constants";


export const login = async (username, password) => {
    try {
        const resp = await axios.post(BASE_URL + "login/", { "username": username, "password": password })
        return resp;
    } catch (error) {
        return error;
    }
}



export const fileUpload = async (token, file) => {
    const headers_ = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Token ' + token,
    }

    const data = {
        file: file,
        source:"csv"
    }

    try {
        const resp = await axios.post(BASE_URL + "process_data/", data, { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}



export const addAsin = async (token, asin) => {
    const headers_ = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token,
    }

    const data = {
        asin: asin,
    }

    try {
        const resp = await axios.post(BASE_URL + "scrapping/", data, { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}

export const getStatus = async (token) => {
    const headers_ = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token,
    }

    try {
        const resp = await axios.get(BASE_URL + "scrapping_status/", { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}


export const getStatements = async (token) => {
    const headers_ = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token,
    }

    try {
        const resp = await axios.get(BASE_URL + "get_statements/", { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}


export const product_description = async (token, name) => {
    const headers_ = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token,
    }

    const data = {
        name: name,
    }

    try {
        const resp = await axios.post(BASE_URL + "product-description/", data, { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}


export const review_analysis = async (token, reviews) => {
    const headers_ = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token,
    }

    const data = {
        reviews: reviews,
    }

    try {
        const resp = await axios.post(BASE_URL + "reviews_input/", data, { headers: headers_ });
        return resp;
    } catch (error) {
        return error;
    }
}
